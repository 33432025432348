<template>
  <v-col cols="11">
    <v-card>
      <app-backfill-pools-table :spotId="spotId" :poolId="poolId" :feedId="feedId"></app-backfill-pools-table>
    </v-card>
  </v-col>
</template>

<script>
import BackfillPoolsTable from "@/components/medusa/BackfillPoolsTable";
import { mapState } from "vuex";

export default {
  components: {
    appBackfillPoolsTable: BackfillPoolsTable
  },

  computed: mapState({
    isEditing: (state) => {
      return state.backfillPools.isEditing;
    },
    unsavedFeeds() {
      return this.$store.state.backfillPools.unsavedFeeds;
    },
    unsavedGeo() {
      return this.$store.state.backfillPools.unsavedGeo;
    },
    unsavedMapping() {
      return this.$store.state.backfillPools.unsavedMapping;
    }
  }),

  data: () => ({
    feedId: null,
    spotId: null,
    poolId: null
  }),
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });

    this.poolId = this.$route.query.poolId;
    this.feedId = this.$route.query.feedId
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isEditing || this.unsavedFeeds) {
      if (!window.confirm("Leave without saving changes?")) {
        return;
      }
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing || this.unsavedFeeds) {
      if (!window.confirm("Leave without saving changes?")) {
        return;
      }
    }
    next();
  },

  methods: {
    preventNav(event) {
      if (!this.isEditing && !this.unsavedFeeds && !this.unsavedGeo && !this.unsavedMapping) return;
      event.preventDefault();
      event.returnValue = "";
    }
  }
};
</script>
